import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import gs from "../services/gs";

import { getMenuObject } from '../services/helper';

const Header = () => {

    const [brand, setBrand] = useState();
    const [menu, setMenu] = useState();

    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        gs.getProperty('sys.brand.logo').then((res) => {
            setBrand(res);
        });
        gs.getMenuData(1).then((res) => {
            setMenu(getMenuObject(res));
        });
    }, []);

    const logout = ()=>{
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    return (
        <header className="header">
            <nav className="main-nav">
                {/* 1st logo part */}
                <div className="logo">
                    <Link className="logo-content" onClick={logout}>
                        <div className='logo-div'>
                            {brand && <img src={`/images/${brand}`} alt='logo' height={40} />}
                        </div>
                        {/* <div className="logo-text-div">
                            <div className='logo-title'>APPA</div>
                            <div className='logo-text'>Automation</div>
                        </div> */}
                    </Link>
                </div>
          
                {/* 2nd menu part */}
                <div className={toggle ? "mobile-menu-link" : "menu-link"}>
                    <Navbar data={menu} />
                </div>

                {/* hamburger menu start */}
                <div className='hamburger-menu'>
                    <button className='btn btn-sm' onClick={() => setToggle(!toggle)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                </div>
            </nav>
        </header>
    );
}

export default Header;