import React, { useState, useRef, useEffect } from 'react';
import MenuItems from './MenuItems';
import TreeView from './tree-view';

const Navbar = ({ data }) => {

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = (item) => {
    setIsOpen(!isOpen);
  };

  function handleMenuItemClick() {
    console.log('Menu item clicked');
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav>
      <ul className="menus">
        <li className="dropdown-all">
          <span onClick={toggleMenu} className="dropdown-button-all">
            All
          </span>
          {isOpen && (
            <div className="dropdown-menu-all" ref={menuRef}>
              <TreeView menus={data} onMenuItemClick={handleMenuItemClick} />
            </div>
          )}
        </li>
        {data && data.map((eachRow, index) => {
          const depthLevel = 0;
          return <MenuItems items={eachRow} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;