import BillHeader from "./bill-header";
import BillItem from "./bill-items";
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Editor } from '@monaco-editor/react';
import gs from '../../services/gs';
import useGForm from '../../hooks/useGForm';
import Reference from "../Reference";
import Select from "../Select";
import "./styles.css";
import Patient from "../patient";


const BillView = ({ }) => {

    const navigate = useNavigate();
    const { paramId } = useParams();

    const [id, setId] = useState();
    const [table, setTable] = useState("bill");
    const [data, setData] = useState();

    useEffect(() => {
        setId(paramId);
    }, [paramId]);

    useEffect(() => {
        if (table !== undefined && id !== undefined) {
            gs.getData(table, id, "").then((res) => {
                setData(res);
                console.log(res);
            });
            // gs.getRelatedLists(table).then((res) => {
            //     setRelatedLists(res);
            //     console.log(res);
            // })
        }
    }, [table, id])

    const g_form = useGForm(data);

    const evalScript = (script) => {
        console.log(script);
        // Use the Function constructor to create a function
        const dynamicFunction = new Function('g_form', 'gs', 'table', 'id', 'navigate', script);
        // Call the dynamically created function
        dynamicFunction(g_form, gs, table, id, navigate);
    }

    const handleChange = (event, eachRow, refDataType) => {

        console.log(event);

        const index = data.findIndex(item => item.id === eachRow.id);
        let temp = [...data]; // important to create a copy, otherwise you'll modify state outside of setState call

        if (eachRow.dataType === "choice" || eachRow.dataType === "reference") {
            if (refDataType && refDataType === "string")
                temp[index].data = event.target.value || null;
            else
                temp[index].data = +event.target.value || 0;
        } else if (eachRow.dataType === "boolean") {
            temp[index].data = !temp[index].data || false;
        } else if (eachRow.dataType === "integer") {
            temp[index].data = +event.target.value || 0;
        } else if (eachRow.dataType === "script") {
            temp[index].data = event;
        } else if (eachRow.dataType === "text") {
            temp[index].data = event.target.innerHTML;
        } else {
            temp[index].data = event.target.value;
        }
        // console.log('setSectionsWD ', temp);
        setData(temp);
    }

    const renderContent = (name) => {

        const eachRow = data.find(item => item.name === name);

        var content;

        switch (eachRow.dataType) {
            case 'reference':
                content = <Reference eachRow={eachRow} handleChange={(e, eachRow) => handleChange(e, eachRow)} />
            case 'choice':
                content = <Select table={table} eachRow={eachRow} handleChange={(e, eachRow) => handleChange(e, eachRow)} />
            case 'boolean':
                content = <input type="checkbox" readOnly={eachRow.readOnly} className="checkbox" checked={eachRow.data} value={eachRow.data} onChange={(e) => handleChange(e, eachRow)} />
            case 'integer':
                content = <input type="number" readOnly={eachRow.readOnly} className="form-control" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow)} />
            case 'text':
            case 'script':
                content = <div className="form-control">
                    <Editor
                        height='250px'
                        width="100%"
                        theme="vs-dark"
                        defaultLanguage={table === 'app_server_rule' ? 'java' : 'javascript'}
                        contentEditable="true"
                        value={eachRow.data}
                        onChange={(e) => handleChange(e, eachRow)}
                    /></div>
            default:
                content = <input type="text" readOnly={eachRow.readOnly} className="form-control disabled" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow)} />

        }
        return content;

    }

    return (

        <div className="">

            <BillHeader title="Billing" table={table} id={id} evalScript={evalScript} />

            <div className="grid-container">

                <div>ID</div> {data && renderContent("id")}
                <div></div><div></div>
                <div>Entry Date</div>  {data && renderContent("entry_date")}

                <div>Patient</div> <div className="span-5"><Patient id="1" /></div>

                <div>Referred By:</div> {data && renderContent("doctor_id")}
                <div>Degree</div><input type="text" />
                <div>Hospital</div><input type="text" />

            </div>

            <BillItem />
        </div>
    );
};

export default BillView;