import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, json } from "react-router-dom";
import Select from '../components/Select';
import { toast } from "react-toastify";
import gs from "../services/gs";
//import { useSysPropertiesByNameSWQuery, useUpdateSysPropertiesMutation } from '../store';

const SysPropertyView = () => {

    const navigate = useNavigate();
    const { prefix } = useParams();

    console.log(prefix);

    const [data, setData] = useState();

    const [updatedData, setUpdatedData] = useState();
    //const { data, error, isLoading } = useSysPropertiesByNameSWQuery(prefix);
    //const [propertiesUpdate] = useUpdateSysPropertiesMutation();

    useEffect(() => {
        gs.getList("app_property", [{ column: 'name', operator: 'STARTSWITH', value: prefix }], "id", "ASC","1").then((res) => {
            setData(res);
            console.log(res);
        });
    }, [prefix])

    //comming from script
    const onUpdate = () => {
        console.log(data);
        gs.updateProperties(data).then((result) => {
            if (result === 'Success')
                toast.success("Records updated");
            else
                toast.error("Please try later");
        });
    }

    const handleChange = (event, property) => {
        setData((prevData) => {
            return prevData.map((item) => {
                if (item.name === property.name) {
                    var newValue = '';
                    switch (property.type) {
                        case 'options':
                        case 'reference':
                            newValue = {
                                label: event.target[event.target.selectedIndex].text, value: event.target.value
                            };
                            break;
                        case 'boolean':
                            newValue = !item.value;
                            break;
                        case 'integer':
                            newValue = event.target.value;
                            break;
                        default:
                            newValue = event.target.value;
                            break;
                    }
                    return { ...item, value: newValue }
                }
                return item;
            })
        });
    }

    const renderSwitch = (eachRow) => {
        switch (eachRow.type) {
            case 'reference':
            case 'options':
                return <Select eachRow={eachRow} handleChange={(e, eachRow) => handleChange(e, eachRow)} />
            case 'boolean':
                return <input type="checkbox" readOnly={eachRow.readOnly} className="checkbox" checked={eachRow.value} value={eachRow.value} onChange={(e) => handleChange(e, eachRow)} />
            case 'integer':
                return <input type="number" readOnly={eachRow.readOnly} className="form-control" value={typeof eachRow.value !== 'object' ? eachRow.value : ""} onChange={(e) => handleChange(e, eachRow)} />
            default:
                return <input type="text" readOnly={eachRow.readOnly} className="form-control disabled" value={typeof eachRow.value !== 'object' ? eachRow.value : ""} onChange={(e) => handleChange(e, eachRow)} />
        }
    }

    return (
        <div>
            <div className='bg-light d-flex justify-content-between align-items-center form-header mb-2 py-1'>
                <label className='ps-2 fw-bold text-secondary'>Report Properties</label>
                <div className=''>
                    <button className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={onUpdate}>Update</button>
                </div>
            </div>
            <div className='container'>
                {data && data.length > 0 && data.map((eachRow) => {
                    return (
                        <div className='container-fluid' key={eachRow.id}>
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <div className="form-group">
                                        <label className="col-xs-12 col-md-1_5 col-lg-2 control-label">{eachRow.description}</label>
                                        <div className="col-xs-10 col-md-9 col-lg-9 form-field input_controls">
                                            {renderSwitch(eachRow, eachRow.id)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div >
        </div>
    )
}
export default SysPropertyView;