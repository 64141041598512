import React, { useState, useEffect } from 'react';
import gs from '../services/gs';
import Modal from './Modal';
import ListPage from '../routes/ListPage';

const Reference = ({ eachRow, handleChange, tabIndex }) => {

    const [data, setData] = useState();
    const [dataType, setDataType] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTable, setModalTable] = useState(eachRow.refTable);

    useEffect(() => {
        if (eachRow.data) {
            gs.getSingleOrFirst(eachRow.refTable, "id", eachRow.data).then((res) => {
                setData(res);
                //console.log(res);
            });
        }
    }, [eachRow]);

    const onModalOpen = (eachRow) => {
        setIsModalOpen(true);
    }

    // calling order Reference->ListPage->ListView
    const onSelect = (selectedRow) => {
        eachRow.data = selectedRow.id;
        setData(selectedRow);
        setIsModalOpen(false);
    }

    return (
        <>
            <Modal handleClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
                <ListPage modalTable={modalTable} isModal={true} onSelect={onSelect} />
            </Modal>
            <div className="input-group">
                <input type="text" tabIndex={tabIndex} readOnly={eachRow.readOnly} className="form-control" value={data ? data[eachRow.refLabel] : ""} onChange={(e) => handleChange(e, eachRow)} />
                <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => onModalOpen(eachRow)}><i className="bi bi-search"></i></button>
            </div>
        </>
    )
}

export default Reference;