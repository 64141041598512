export const Type = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    DEFAULT: 'default'
}

export const Default = {
    COLLAPSE_DURATION: 300,
    DEBOUNCE_DURATION: 50,
    CSS_NAMESPACE: 'Modalify',
    DRAGGABLE_PERCENT: 80,
    CONTAINER_ID: 1
}

export const Direction = {
    X: 'x',
    Y: 'y'
}