import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gs from '../../services/gs';

function Brand() {
    const [brand, setBrand] = useState();

    useEffect(() => {
        gs.getProperty('sys.brand.logo').then((res) => {
            setBrand(res);
        });
    }, []);

    return (
        <div className="logo">
            <Link className="logo-content" to="/">
                <div className='logo-div'>
                    {brand && <img src={`/images/${brand}`} alt='logo' height={40} />}
                </div>
            </Link>
        </div>
    )
}

export default Brand;