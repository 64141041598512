import { useState } from "react";
import Select from "./Select";
import gs from "../services/gs";

const FilterItem = ({ index, columns, handleFieldChange, handleOperatorChange, handleValueChange, handleAddClick, handleRemove }) => {

    const [selColumn, setSelColumn] = useState();
    const [operators, setgetOperators] = useState();

    const handleFieldType = (event) => {
        const { name, value } = event.target;
        let tColumn = columns.find(item => item.name === value);
        console.log(tColumn);
        setSelColumn(tColumn);
        gs.getOperators(tColumn.dataType).then((res) => {
            setgetOperators(res);
        });
    }

    function renderFilter() {
        return (
            <div style={{ width: "10rem" }} className="me-2">
                {selColumn && (() => {
                    switch (selColumn['dataType']) {
                        case 'reference':
                            return <Select name="value" eachRow={selColumn} handleChange={(e) => handleValueChange(e)} />;
                        case 'options':
                            return <Select name="value" eachRow={selColumn} handleChange={(e) => handleValueChange(e)} />;
                        case 'boolean':
                            return <input name="value" type="checkbox" className="checkbox" onChange={(e) => handleValueChange(e)} />;
                        case 'integer':
                            return <input name="value" type="number" className="form-control" onChange={(e) => handleValueChange(e)} />;
                        default:
                            return <input name="value" type="text" className="form-control" onChange={(e) => handleValueChange(e)} />;
                    }
                })()}
            </div>
        )
    };

    return (
        <tr className="d-flex ms-1">
            <td>
                <select style={{ width: "10rem" }} className="me-2" name="field" onChange={e => { handleFieldChange(e); handleFieldType(e) }}>
                    <option value="0">Choose field</option>
                    {columns && columns.map((eachOption) => {
                        return <option key={eachOption.id} value={eachOption.name}>{eachOption.label}</option>
                    })}
                </select>
            </td>
            <td>
                <select style={{ width: "10rem" }} className="me-2" name="field" onChange={e => { handleOperatorChange(e)}}>
                    <option value="0">Choose operator</option>
                    {operators && operators.map((operator) => {
                        return <option key={operator.id} value={operator.value}>{operator.label}</option>
                    })}
                </select>
            </td>
            <td>
                {renderFilter()}
            </td>
            <td>
                <button className="btn btn-outline-primary bg-white btn-sm me-1" onClick={handleAddClick}>AND</button>
                <button className="btn btn-outline-danger bg-white btn-sm" onClick={() => handleRemove(index)}><i className="bi bi-x" style={{ fontSsize: "2rem" }}></i></button>
            </td>
        </tr >
    )
}

export default FilterItem;