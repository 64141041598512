import React, { useState, useEffect } from 'react';
import MenuItem from './MenuItem';
import gs from '../../services/gs';
import "./styles.css";

const Menu = () => {
    const [data, setData] = useState();

    useEffect(() => {
        gs.getList("app_menu", "", [{ column: 'type', operator: 'IS', value: "3" }], "sort_order", "ASC", 1).then((response) => {
            setData(response);
        })
    }, []);

    return (
        <ul className="menu">
            {data && data.map((eachRow, index) => {
                const depthLevel = 0;
                return <MenuItem items={eachRow} key={index} depthLevel={depthLevel} />;
            })}
        </ul>
    );
};

export default Menu;