import "./styles.css";

const BillItem = () => {
  return (
    <div className="">
      BillItem
    </div>
  );
};

export default BillItem;