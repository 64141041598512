import { useState } from "react";
import MenuList from "./menu-list";
import { Link } from "react-router-dom";

const MenuItem = ({ item, onMenuItemClick }) => {
  const [displaySubmenu, setDisplaySubmen] = useState({});

  function handleToggleChildren(currentId) {
    setDisplaySubmen({
      ...displaySubmenu,
      [currentId]: !displaySubmenu[currentId],
    });
  }

  return (
    <li>
      <div className="menu-item">
        {/* <p>{item.label}</p> */}
        <div>
          {item.url.startsWith("http") ? (
            <a href={item.url} target="_blank" rel="noreferrer">
              {item.label}
            </a>
          ) : item && item.submenu && item.submenu.length ? (
            <div onClick={() => handleToggleChildren(item.id)}>
              {item.label}
            </div>
          ) : (
            <Link to={item.url}>
              <div onClick={onMenuItemClick}>{item.label}</div>{" "}
            </Link>
          )}
        </div>
        {item && item.submenu && item.submenu.length ? (
          <span onClick={() => handleToggleChildren(item.id)}>
            {displaySubmenu[item.id] ? (
              <i
                className="bi bi-dash-square"
                style={{ fontSize: "1rem", color: "red" }}
              ></i>
            ) : (
              <i
                className="bi bi-plus-square"
                style={{ fontSize: "1rem", color: "green" }}
              ></i>
            )}
          </span>
        ) : null}
      </div>

      {item &&
      item.submenu &&
      item.submenu.length > 0 &&
      displaySubmenu[item.id] ? (
        <MenuList list={item.submenu} onMenuItemClick={onMenuItemClick} />
      ) : null}
    </li>
  );
};

export default MenuItem;
