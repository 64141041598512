import React, { useEffect, useState, useRef } from 'react';
import gs from '../../services/gs';
import "./styles.css";

const BillHeader = ({ title, table, evalScript }) => {

    const [UIActions, setUIActions] = useState();

    useEffect(() => {
        if (table !== undefined) {
            gs.getUIActions(table).then((res) => {
                setUIActions(res);
                //console.log(res);
            });
        }
    }, [table])

    return (
        <div className="">
            <div className='bg-light d-flex justify-content-between align-items-center form-header mb-2 py-1'>
                <label className='ps-2 fw-bold text-secondary'>{title}</label>
                <div className=''>
                    {UIActions && UIActions.map((action) => {
                        return (
                            <button key={action.id} className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={() => evalScript(action.script)}>{action.name}</button>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default BillHeader;