export const getMenuObject = (data) => {
  // Deep copy the data to avoid mutating the original array
  const menuData = JSON.parse(JSON.stringify(data));
  const menu = [];

  menuData.forEach(element => {
    if (element.parent === 0) {
      // If the element has no parent, it's a top-level menu item
      menu.push(element);
    } else {
      // Try to find the parent in the top-level menu
      const parentIndex = menu.findIndex(item => item.id === element.parent);

      if (parentIndex > -1) {
        // If found, add the element to the submenu
        if (menu[parentIndex].submenu) {
          menu[parentIndex].submenu.push(element);
        } else {
          menu[parentIndex].submenu = [element];
        }
      } else {
        // If not found, search in submenus of top-level items
        let found = false;
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].submenu) {
            const subIndex = menu[i].submenu.findIndex(subItem => subItem.id === element.parent);

            if (subIndex > -1) {
              // If the parent is found in a submenu, add the element to the subsubmenu
              if (menu[i].submenu[subIndex].submenu) {
                menu[i].submenu[subIndex].submenu.push(element);
              } else {
                menu[i].submenu[subIndex].submenu = [element];
              }
              found = true;
              break;
            }
          }
        }
        if (!found) {
          console.warn(`Parent ID ${element.parent} not found for element ID ${element.id}`);
        }
      }
    }
  });

  return menu;
};

export const findColumnData = (data, columnName) => {
  if (data.sections) {
    for (const section of data.sections) {
      if (section.elements) {
        for (const element of section.elements) {
          if (element.column && element.column.name === columnName) {
            return element.column.data;
          }
        }
      }
    }
  }
  return null;
}

export const getNewObj = (data, columnName) => {
  if (data.sections) {
    for (const section of data.sections) {
      if (section.elements) {
        for (const element of section.elements) {
          if (element.column && element.column.name === columnName) {
            return element.column.data;
          }
        }
      }
    }
  }
  return null;
}