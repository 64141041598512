import React, { useState, useEffect } from 'react';
import ListView from './ListView';
import gs from '../services/gs';

const RelatedListTab = ({ table, queryWith}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [dbObject, setDbObject] = useState();
    const [columns, setColumns] = useState();
    const [data, setData] = useState();
    const [pagination, setPagination] = useState();
    const [UIActions, setUIActions] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterConditions, setFilterConditions] = useState([]);

    useEffect(() => {
        if (table !== undefined) {
            gs.getById("1", "db_object", table).then((res) => {
                setDbObject(res);
                console.log(res);
            });
        }
    }, [table, queryWith]);

    useEffect(() => {
        if (dbObject !== undefined) {
            gs.getListColumns("1", dbObject.name).then((res) => {
                setColumns(res);
                console.log(res);
            });
            console.log(queryWith);
            gs.getListData(dbObject.name, queryWith, "id", "ASC", 1, rowsPerPage).then((res) => {
                setData(res.data);
                setPagination(res.pagination);
                console.log(res.pagination);
            });
            gs.getUIActions(dbObject.name, "list").then((res) => {
                setUIActions(res);
                console.log(res);
            });
        }
    }, [dbObject, rowsPerPage]);

    const onPagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const onRowsPerPageChange = (rowsPerPage) => {
        console.log(filterConditions);
        setRowsPerPage(rowsPerPage)
        gs.getListData(dbObject.name, filterConditions, "id", "ASC", currentPage, rowsPerPage).then((res) => {
            setData(res.data);
            setPagination(res.pagination);
            //console.log(res);
        });
    }

    const onFilter = (filterCondition) => {
        console.log(filterCondition);
        setFilterConditions(filterCondition);
        setCurrentPage(1);
        gs.getListData(dbObject.name, filterCondition, "id", "ASC", currentPage, rowsPerPage).then((res) => {
            setData(res.data);
            setPagination(res.pagination);
            //console.log(res);
        });
    }

    return (
        <div className="">
            {(dbObject && columns && data && pagination) && <ListView table={dbObject.name} title={dbObject.label} columns={columns} data={data} UIActions={UIActions} showFilter="true" onFilter={onFilter} pagination={pagination} onPagination={onPagination} onRowsPerPageChange={onRowsPerPageChange} />}
        </div>
    )
}

export default RelatedListTab;