import MenuList from "./menu-list";
import "./styles.css";

const TreeView = ({ menus = [], onMenuItemClick  }) => {
  return (
    <div className="tree-view-container">
      <MenuList list={menus} onMenuItemClick={onMenuItemClick} />
    </div>
  );
};

export default TreeView;
