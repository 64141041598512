import React, { useEffect, useState, useRef } from 'react';
import Select from "../Select";
import "./styles.css";

const Age = ({ inputValue, handleChange }) => {

    const splitAge = inputValue.split("-");
    const [age, setAge] = useState(splitAge[0]);
    const [calendrical, setCalendrical] = useState(splitAge[1]);

    const [data, setData] = useState({ "name": "age", "data": calendrical, "dataType": "choice" });

    const handleChangeInput = (e, data) => {
        const tAge = e.target.value;
        setAge(tAge);
        let customEvent = { "target": { "value": tAge + "-" + calendrical, "name": "age" } };
        handleChange(customEvent);
    }

    const handleChangeSelect = (e, data) => {
        const tCalendrical = e.target.value;
        setCalendrical(tCalendrical);
        setData({ "name": "age", "data": tCalendrical, "dataType": "choice" });
        let customEvent = { "target": { "value": age + "-" + tCalendrical, "name": "age" } };
        handleChange(customEvent);
    }

    return (
        <div style={{ width: "100%" }} className="input-group">
            <input style={{ width: "30%" }} className="form-control" maxLength="2" type="text" value={age} onChange={handleChangeInput} />
            <Select style={{ width: "70%" }} className="form-control" table="patient" eachRow={data} handleChange={handleChangeSelect} />
        </div>
    );
};

export default Age;