import React, { useState } from 'react';
import { Editor } from '@monaco-editor/react';
import gs from "../services/gs";

const ScriptPad = () => {

    const [script, setScript] = useState("//output = string value\n");
    const [scriptResult, setScriptResult] = useState();

    //comming from script
    const onExecute = () => {
        gs.executeServerScipt(script).then((result) => {
            setScriptResult(result);
        });
    }

    const handleChange = (event) => {
        setScript(event);
    }

    return (
        <div>
            <div className='bg-light d-flex justify-content-between align-items-center form-header mb-2 py-1'>
                <label className='ps-2 fw-bold text-secondary'>Script Pad</label>
                <div className=''>
                    <button className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={onExecute}>Execute</button>
                </div>
            </div>
            <div className='container'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <div className="form-group" style={{ height: "450px", marginTop: "1rem" }}>
                                <div className="col-xs-10 col-md-9 col-lg-9 form-field input_controls">
                                    <div className="form-control">
                                        <Editor
                                            height='450px'
                                            width="100%"
                                            theme="vs-dark"
                                            defaultLanguage={'java'}
                                            value={script}
                                            contentEditable="true"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3 col-lg-3" style={{ paddingLeft: "0.5rem" }}>
                                    <div className="form-control">
                                        <label>Script output:</label>
                                        <div style={{ height: "420px", overflow: "auto" }}>{scriptResult}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
export default ScriptPad;