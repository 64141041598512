import React from 'react'
import MegaMenu from '../mega-menu'
import Brand from './Brand'
import Menu from '../menu'


function Header() {
    return (
        <div className="header">
            <MegaMenu />
            <div className='brand-menu'>
                <Brand />
                <Menu />
            </div>
        </div>
    )
}

export default Header