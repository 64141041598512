import React, { useEffect, useState } from "react";
import gs from "services/gs";

function PdfViewer() {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {

        gs.genPDF(1, 1)
            .then((response) => {
                if (response.status === 200) {
                    const pdfBlob = response.data; // This will be the Blob containing the PDF

                    if (!(pdfBlob instanceof Blob)) {
                        throw new Error("Response is not a valid Blob.");
                    }

                    // Create a URL for the Blob
                    const pdfBlobUrl = URL.createObjectURL(pdfBlob);
                    //window.open(pdfBlobUrl);
                    setPdfUrl(pdfBlobUrl);
                } else {
                    throw new Error("Failed to fetch PDF");
                }
            })
            .catch((error) => {
                console.error("Error fetching PDF:", error);
                setError("Failed to load PDF. Please try again.");
            });
    }, []);

    return (
        <div>
            {error && <p>{error}</p>}
            {pdfUrl ? (
                <object data={pdfUrl} type="application/pdf" width="100%" height="600px">
                    <p>It appears your browser does not support PDFs. Please download the PDF to view it: <a href={pdfUrl}>Download PDF</a>.</p>
                </object>
            ) : (
                !error && <p>Loading PDF...</p>
            )}
        </div>
    );
}

export default PdfViewer;
