import { Default, canBeRendered, isId } from '../utils';
import { createContainerObserver } from './containerObserver';

const containers = new Map();
let renderQueue = [];
const listeners = new Set();

const dispatchChanges = (data) => listeners.forEach(cb => cb(data));

const hasContainers = () => containers.size > 0;

function flushRenderQueue() {
    renderQueue.forEach(v => pushToast(v.content, v.options));
    renderQueue = [];
}

export const getModal = (id, { containerId }) =>
    containers.get(containerId || Default.CONTAINER_ID)?.toasts.get(id);

export function isModalActive(id, containerId) {
    if (containerId) return !!containers.get(containerId)?.isToastActive(id);

    let isActive = false;
    containers.forEach(c => {
        if (c.isToastActive(id)) isActive = true;
    });

    return isActive;
}

export function removeToast(params) {
    if (!hasContainers()) {
        renderQueue = renderQueue.filter(
            v => params != null && v.options.toastId !== params
        );
        return;
    }

    if (params == null || isId(params)) {
        containers.forEach(c => {
            c.removeToast(params);
        });
    } else if (params && ('containerId' in params || 'id' in params)) {
        const container = containers.get(params.containerId);
        container
            ? container.removeToast(params.id)
            : containers.forEach(c => {
                c.removeToast(params.id);
            });
    }
}

export function clearWaitingQueue(p = {}) {
    containers.forEach(c => {
        if (c.props.limit && (!p.containerId || c.id === p.containerId)) {
            c.clearQueue();
        }
    });
}

export function pushToast(content, options) {
    if (!canBeRendered(content)) return;
    if (!hasContainers()) renderQueue.push({ content, options });

    containers.forEach(c => {
        c.buildToast(content, options);
    });
}

export function registerToggle(opts) {
    containers
        .get(opts.containerId || Default.CONTAINER_ID)
        ?.setToggle(opts.id, opts.fn);
}

export function toggleToast(v, opt) {
    containers.forEach(c => {
        if (opt == null || !opt?.containerId) {
            c.toggle(v, opt?.id);
        } else if (opt?.containerId === c.id) {
            c.toggle(v, opt?.id);
        }
    });
}

export function registerContainer(props) {
    const id = props.containerId || Default.CONTAINER_ID;
    return {
        subscribe(notify) {
            const container = createContainerObserver(id, props, dispatchChanges);

            containers.set(id, container);
            const unobserve = container.observe(notify);
            flushRenderQueue();

            return () => {
                unobserve();
                containers.delete(id);
            };
        },
        setProps(p) {
            containers.get(id)?.setProps(p);
        },
        getSnapshot() {
            return containers.get(id)?.getSnapshot();
        }
    };
}

export function onChange(cb) {
    listeners.add(cb);

    return () => {
        listeners.delete(cb);
    };
}