import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';
import gs from '../services/gs';

const LayoutAuxin = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      gs.getProfile().then(response => {
        console.log(response);
      })
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <Header />
      <div className="content" style={{ flexGrow: 1 }}>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutAuxin;