import Age from "../age";
import "./styles.css";

const Patient = ({ id, className }) => {

    const handleChange = (event) => {
        console.log(event);
    }

    return (
        <div className={className}>
            <div>Name</div> <div>Shivappa</div>
            <div>Gender</div><input type="text" />
            <div>Age</div><Age inputValue="42-0" handleChange={handleChange} />
        </div>
    );
};

export default Patient;