import axios from "axios";

//export const BASE_URL = "http://103.47.226.178:8080/auxin/api/";
//export const BASE_URL = "http://localhost:8080/api/v1";

//1 The variable must be prefixed with REACT_APP_
//2. Make sure you have the .env file in your root folder(same place where you have your package.json) and NOT in your src folder.
//3. No need any npm installation

const openAxios = axios.create({
  //baseURL: BASE_URL
  baseURL: process.env.REACT_APP_BASE_URL
});

export default openAxios;