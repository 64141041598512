const Pagination = ({ pagination, onPaginationClick, onRowsPerPageChange }) => {

    const pages = [...Array(pagination.totalPages).keys()].map((num) => num + 1);
    const first5 = Array.from({ length: 5 }, (_, index) => index + 1);
    const last5 = Array.from({ length: 5 }, (_, index) => pagination.totalPages - 4 + index);

    return (
        <div className="table-pagination d-flex justify-content-between align-items-center py-1">
            <div className="ms-2">
                Showing {((pagination.currentPage * pagination.pageSize) - pagination.pageSize) + 1} - {(pagination.currentPage * pagination.pageSize)} of total {pagination.totalCount}
            </div>
            <div >
                {pagination.totalPages > 1 ? (<nav className='d-flex justify-content-center pagination'>
                    <ul className='pagination m-0 d-flex items-center justify-content-center me-2'>
                        <li className="page-item">
                            <button disabled={!pagination.hasPrevious} className="page-link"
                                onClick={() => {
                                    if (pagination.hasPrevious) {
                                        var pageNumber = pagination.currentPage - 1;
                                        onPaginationClick(pageNumber);
                                    }
                                }}
                            >&lt;</button>
                        </li>
                    </ul>
                    {pagination.totalPages > 10 ? (<div className='pagination d-flex items-center justify-content-center'>
                        <ul className='pagination m-0 d-flex items-center justify-content-center'>
                            {first5.map((page) => {
                                return (
                                    <li key={page} className={page === pagination.currentPage ? "page-item active" : "page-item"}>
                                        <div onClick={onPaginationClick.bind(this, page)} className="page-link">
                                            {page}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="ms-2 me-2">. . . .</div>
                        <ul className='pagination m-0 d-flex items-center justify-content-center'>
                            {last5.map((page) => {
                                return (
                                    <li key={page} className={page === pagination.currentPage ? "page-item active" : "page-item"}>
                                        <div onClick={onPaginationClick.bind(this, page)} className="page-link">
                                            {page}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>) : (
                        <ul className='pagination m-0 d-flex items-center justify-content-center'>
                            {pages.map((page) => {
                                return (
                                    <li key={page} className={page === pagination.currentPage ? "page-item active" : "page-item"}>
                                        <div onClick={onPaginationClick.bind(this, page)} className="page-link">
                                            {page}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                    <ul className='pagination m-0 d-flex items-center justify-content-center ms-2'>
                        <li className="page-item">
                            <div disabled={!pagination.hasNext} className="page-link"
                                onClick={() => {
                                    if (pagination.hasNext) {
                                        var pageNumber = pagination.currentPage + 1;
                                        onPaginationClick(pageNumber);
                                    }
                                }}
                            >&gt;</div>
                        </li>
                    </ul>
                </nav>) : null
                }
            </div>
            <div className="me-2">
                Rows per page : 
                <select style={{ width: "4rem" }} className="me-2" name="field" onChange={e => { onRowsPerPageChange(e.target.value) }}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
    )
}

export default Pagination;