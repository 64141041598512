import cx from 'clsx';
import React, { useRef } from 'react';

import { useModalContainer } from 'hooks/useModalContainer';
import { Default, Direction, isFn, parseClassName } from 'utils';
import { Modal } from './Modal';
import { Zoom } from './Transitions';

export const defaultProps = {
  position: 'top-right',
  transition: Zoom,
  closeButton: true,
  draggable: 'touch',
  draggablePercent: Default.DRAGGABLE_PERCENT,
  draggableDirection: Direction.X,
  role: 'alert',
  theme: 'light',
};

export function ModalContainer(props) {
  let containerProps = {
    ...defaultProps,
    ...props,
  };
  const stacked = props.stacked;
  const containerRef = useRef(null);
  const { getModalToRender, isModalActive } =
    useModalContainer(containerProps);
  const { className, style, rtl, containerId } = containerProps;

  function getClassName(position) {
    const defaultClassName = cx(
      `${Default.CSS_NAMESPACE}__modal-container`,
      `${Default.CSS_NAMESPACE}__modal-container--${position}`,
      { [`${Default.CSS_NAMESPACE}__modal-container--rtl`]: rtl },
    );
    return isFn(className)
      ? className({
          position,
          rtl,
          defaultClassName,
        })
      : cx(defaultClassName, parseClassName(className));
  }

  return (
    <div
      ref={containerRef}
      className={Default.CSS_NAMESPACE}
      id={containerId}
    >
      {getModalToRender((position, toastList) => {
        const containerStyle = !toastList.length
          ? { ...style, pointerEvents: 'none' }
          : { ...style };

        return (
          <div
            className={getClassName(position)}
            style={containerStyle}
            key={`container-${position}`}
          >
            {toastList.map(({ content, props: toastProps }) => {
              return (
                <Modal
                  {...toastProps}
                  stacked={stacked}
                  isIn={isModalActive(
                    toastProps.toastId,
                    toastProps.containerId,
                  )}
                  style={toastProps.style}
                  key={`toast-${toastProps.key}`}
                >
                  {content}
                </Modal>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
