import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gs from '../../services/gs';
import "./styles.css";

const ContentBlock = ({ value, bgcolor }) => {
    const [data, setData] = useState();

    useEffect(() => {
        gs.getSingleOrFirst("prtl_content_block", "id", value).then(res => {
            console.log(res);
            setData(res);
        });
    }, [value]);

    return (
        <div className="section" style={{backgroundColor: bgcolor}}>
            <div className="container" data-aos="fade-up" >
                {data && <h2 className="heading">{data.title}</h2>}
                <div className="flex-container">
                    {data && data.image_position === "1" && <div className="img-child-container">
                        <img src={data.image_url} className="img-fluid w-100" alt="about-img" />
                    </div>}
                    <div className="text-child-container">
                        {data && <div dangerouslySetInnerHTML={{ __html: data.content }} />}
                        <Link className="read-more-btn" data-aos="fade-right" to="/page/about-us">
                            Read More
                        </Link>
                    </div>
                    {data && data.image_position === "2" && <div className="img-child-container">
                        <img src={data.image_url} className="img-fluid w-100" alt="about-img" />
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default ContentBlock;