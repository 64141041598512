import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import gs from '../services/gs';
import Select from './Select';
import RelatedList from './RelatedList';
import useGForm from '../hooks/useGForm';
import SkeletonList from './SkeletonList';
import { Editor } from '@monaco-editor/react';
import Reference from './Reference';
import Modal from './Modal';
import * as Babel from '@babel/standalone';
import ReactDOM from 'react-dom/client';  // React 18+ uses createRoot

const FormView = ({ modalTable, modalId, isModal = false }) => {

    const navigate = useNavigate();
    const { paramTable, paramId } = useParams();

    const [table, setTable] = useState();
    const [id, setId] = useState();

    //only used to show the modal dialog
    const [showModalTable, setShowModalTable] = useState();
    const [showModalId, setShowModalId] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [sectionsWD, setSectionsWD] = useState();
    const [action, setAction] = useState();

    const [data, setData] = useState();
    const [sections, setSections] = useState();

    const [dbObject, setDbObject] = useState();
    const [UIActions, setUIActions] = useState();
    const [relatedLists, setRelatedLists] = useState();

    useEffect(() => {
        setTable(isModal ? modalTable : paramTable);
        setId(isModal ? modalId : paramId);
        console.log(paramTable);
        console.log(paramId);
    }, [paramTable, paramId, isModal, modalTable, modalId]);

    useEffect(() => {
        console.log("Second useEffect executed");
        if (table !== undefined && id !== undefined) {
            gs.getFormData("1", table, id).then((res) => {
                setData(res);
                setSections(res.sections);
                //console.log(res);
            });

            gs.getUIActions(table).then((res) => {
                setUIActions(res);
                //console.log(res);
            });
            gs.getRelatedLists(table).then((res) => {
                setRelatedLists(res);
                //console.log(res);
            })
        }
    }, [table, id])

    const g_form = useGForm(sections);

    //comming from script
    const onSave = () => {
        // var maping = []; sectionsWD.forEach((eachSec) => { maping = maping.concat(eachSec.columns.map(item => ({ [item.name]: item.data }))); }); var newObj = Object.assign({}, ...maping); addData(newObj).then((resp) => { if (resp.data) { toast.success("Record created"); navigate(-1); } else { var error = resp.errors[0].message; console.log(error); toast.error(error); } }).catch((error) => { console.log(error); toast.error(error.message); });
    }

    const onUpdate = (g_form, updateData, id) => {

        // var maping = [];

        // sectionsWD.forEach((eachSec) => {
        //     maping = maping.concat(eachSec.columns.map(item => ({ [item.name]: item.data })));
        // });

        // var newObj = Object.assign({}, ...maping);

        var newObj = {
            id: g_form.getValue('id'),
            groupId: g_form.getValue('groupId'),
            name: g_form.getValue('name'),
        };

        console.log(newObj);

        gs.updateData({ id: +id, input: newObj }).then((result) => {
            g_form.addSuccessMessage("Record updated");
            navigate(-1);
        }).catch((error) => {
            console.log(error);
            g_form.addErrorMessage(error);
        });

    }

    const onNew = () => {
        navigate('/admin/form/' + dbObject.name);
        setId(null);
        setAction('new');
        var sections = data.sections.forEach((eachSec) => {
            eachSec.columns.map((col) => {
                return col.data = undefined // Set new field
            });
        });
        setSectionsWD(sections);
    }

    const onDelete = () => {
        gs.deleteRow(table, id).then((resp) => {
            g_form.addSuccessMessage("Record Deleted");
        }).catch((error) => {
            console.log(error);
            g_form.addErrorMessage(error.message);
        });
    }

    const evalScript = (script) => {
        console.log(script);

        const transformedCode = Babel.transform(script, {
            presets: ['react'],
        }).code;

        // Use the Function constructor to create a function
        const dynamicFunction = new Function('g_form', 'gs', 'table', 'id', 'navigate', transformedCode);
        // Call the dynamically created function
        dynamicFunction(g_form, gs, table, id, navigate);
    }

    const handleChange = (event, eachRow, secId, refDataType) => {

        const index = sections.findIndex(item => item.id === secId);
        let temp = [...sections]; // important to create a copy, otherwise you'll modify state outside of setState call

        let colIdx = temp[index].elements.findIndex(item => item.column.name === eachRow.name);

        if (eachRow.dataType === "choice" || eachRow.dataType === "reference") {
            if (refDataType === "string")
                temp[index].elements[colIdx].column.data = event.target.value || null;
            else
                temp[index].elements[colIdx].column.data = +event.target.value || 0;
        } else if (eachRow.dataType === "boolean") {
            temp[index].elements[colIdx].column.data = !temp[index].elements[colIdx].column.data || false;
        } else if (eachRow.dataType === "integer") {
            temp[index].elements[colIdx].column.data = +event.target.value || 0;
        } else if (eachRow.dataType === "script") {
            temp[index].elements[colIdx].column.data = event;
        } else if (eachRow.dataType === "text") {
            temp[index].elements[colIdx].column.data = event;
        } else {
            temp[index].elements[colIdx].column.data = event.target.value;
        }
        // console.log('setSectionsWD ', temp);
        setSections(temp);
    }

    const executeCode = () => {
        console.log('executeCode running');
        g_form.addSuccessMessage('error');
    }

    // const executeCode = () => {
    //     const body = { "messaging_product": "whatsapp", "to": "918861012023", "type": "template", "template": { "name": "hello_world", "language": { "code": "en_US" } } };

    //     gs.getById("1", "app_integration", "1").then(resp => {
    //         console.log(resp);
    //         gs.postExternal(resp.end_point, resp.token, body).then((res) => {
    //             console.log(res);
    //         });
    //     });
    //     // const url = "https://graph.facebook.com/v20.0/378548085340348/messages";
    //     // const token = "EAAk3FgSpZCnMBO1ap31lDKT3u5hy3aCOmCYU6BrZCRdwfRy2OvFPcucYQLbwPQGqo9xidcF8Ce3FIJcDejSnZAtgxRHJLviWSCvbE1McM4xXZC5CV5FnwiZBFWiQk4nPrkqyAp01whV4L025zQ2f0w7RdDOsfOTBACrZCAE4xnQG0iFqWNtxE2ryHpx22FZCfLgTNr8RQKNQlAl2ijisZBZCV98PqEyWI99zvegMZD";

    // }

    const onModalClose = () => {
        console.log("on modal close clicked");
        setIsModalOpen(false);
    }

    const onModalOpen = (eachCol) => {

        setShowModalTable(eachCol.refTable);

        gs.getSingleOrFirst(eachCol.refTable, "id", eachCol.data).then((res) => {
            setShowModalId(res.id);
            console.log(res);
        });

        setIsModalOpen(true);
    }

    const renderSwitch = (eachRow, secId, sortOrder) => {
        switch (eachRow.dataType) {
            case 'reference':
                return <Reference eachRow={eachRow} tabIndex={sortOrder} handleChange={(e, eachRow) => handleChange(e, eachRow, secId)} />
            case 'choice':
                return <Select table={table} tabIndex={sortOrder} eachRow={eachRow} handleChange={(e, eachRow) => handleChange(e, eachRow, secId)} />
            case 'boolean':
                return <input type="checkbox" tabIndex={sortOrder} readOnly={eachRow.readOnly} className="checkbox" checked={eachRow.data} value={eachRow.data} onChange={(e) => handleChange(e, eachRow, secId)} />
            case 'integer':
                return <input type="number" tabIndex={sortOrder} readOnly={eachRow.readOnly} className="form-control" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow, secId)} />
            case 'text':
                return <div className="form-control">
                    <Editor
                        height='250px'
                        width="100%"
                        theme="vs-dark"
                        defaultLanguage='html'
                        contentEditable="true"
                        value={eachRow.data}
                        onChange={(e) => handleChange(e, eachRow, secId)}
                    /></div>
            case 'script':
                return <div className="form-control">
                    <Editor
                        height='250px'
                        width="100%"
                        theme="vs-dark"
                        defaultLanguage={table === 'app_server_rule' ? 'java' : 'javascript'}
                        contentEditable="true"
                        value={eachRow.data}
                        onChange={(e) => handleChange(e, eachRow, secId)}
                    /></div>
            case 'password':
                return <input type="password" tabIndex={sortOrder} readOnly={eachRow.readOnly} className="form-control disabled" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow, secId)} />
            default:
                return <input type="text" tabIndex={sortOrder} readOnly={eachRow.readOnly} className="form-control disabled" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow, secId)} />
        }
    }

    return (
        <div>
            <Modal handleClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
                <FormView isModal={true} modalTable={showModalTable} modalId={showModalId} />
            </Modal>
            <div className='bg-light d-flex justify-content-between align-items-center form-header mb-2 py-1'>
                {data && <label className='ps-2 fw-bold text-secondary'>{data.title}</label>}
                <div className=''>
                    {UIActions && UIActions.map((action) => {
                        return (
                            <button key={action.id} className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={() => evalScript(action.script)}>{action.name}</button>
                        )
                    })}
                    {/* <button key="12" className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={() => executeCode()}>Execute Code</button> */}
                </div>
            </div>
            <div className='container' style={{ marginBottom: '1rem' }}>
                {data && sections && sections.map((eachSec) => {
                    return (
                        <div className='container-fluid' key={eachSec.id}>
                            {eachSec.placing === 1 ? (
                                <div className='row'>
                                    <div className='col-xs-12'>
                                        {eachSec.elements && eachSec.elements.map((eachElement) => {
                                            return (
                                                <div key={eachElement.column.id} className="form-group" style={{ alignItems: eachElement.column.dataType !== 'script' ? 'center' : 'flex-start' }}>
                                                    <label className="col-xs-12 col-md-1_5 col-lg-2 control-label" style={{ verticalAlign: 'top', width: '16.00%' }} >{eachElement.column.label}</label>
                                                    <div className="col-xs-10 col-md-9 col-lg-9 form-field input_controls" style={{ width: '76%' }} >
                                                        {renderSwitch(eachElement.column, eachSec.id, eachElement.sortOrder)}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        {eachSec.elements && eachSec.elements.map((eachEl1) => {
                                            return (
                                                eachEl1.position === 1 ? (
                                                    <div key={eachEl1.id} className="form-group">
                                                        <label className="col-xs-12 col-md-3 col-lg-4 control-label" >{eachEl1.column.label}</label>
                                                        <div className="col-xs-10 col-sm-9 col-md-6 col-lg-6 form-field input_controls">
                                                            {renderSwitch(eachEl1.column, eachSec.id, eachEl1.sortOrder)}
                                                        </div>
                                                        {eachEl1.column.dataType === 'reference' && !!eachEl1.column.data && <button className="btn btn-outline-secondary ms-1" type="button" id="button-addon2" onClick={() => onModalOpen(eachEl1.column)}><i className="bi bi-info-circle"></i></button>}
                                                    </div>
                                                ) : null
                                            )
                                        })}
                                    </div>
                                    <div className='col-sm-6'>
                                        {eachSec.elements && eachSec.elements.map((eachEl2) => {
                                            return (
                                                eachEl2.position === 2 ? (
                                                    <div key={eachEl2.id} className="form-group">
                                                        <label className="col-xs-12 col-md-3 col-lg-4 control-label" >{eachEl2.column.label}</label>
                                                        <div className="col-xs-10 col-sm-9 col-md-6 col-lg-6 form-field input_controls">
                                                            {renderSwitch(eachEl2.column, eachSec.id, eachEl2.sortOrder)}
                                                        </div>
                                                        {eachEl2.column.dataType === 'reference' && !!eachEl2.column.data && <button className="btn btn-outline-secondary ms-1" type="button" id="button-addon2" onClick={() => onModalOpen(eachEl2.column)}><i className="bi bi-info-circle"></i></button>}
                                                    </div>
                                                ) : null
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div >
            {data && relatedLists && !isModal && id > 0 && <RelatedList title="Subjects" relatedLists={relatedLists} current={data} />}
        </div>
    )
}
export default FormView;