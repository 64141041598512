import React, { useState, useEffect } from 'react';
import gs from '../services/gs';

const Select = ({ table, eachRow, handleChange, style, tabIndex }) => {

    const [data, setData] = useState();

    useEffect(() => {
        if (eachRow.name) {
            gs.getChoices(table, eachRow.name).then((res) => {
                setData(res);
                //console.log(res);
            });
        }
    }, [table, eachRow]);

    return (
        <select style={style} tabIndex={tabIndex} className="form-select" aria-label="Default select example" defaultValue='' value={eachRow.data !== undefined ? eachRow.data : ''} onChange={(e) => handleChange(e, eachRow, eachRow.dataType)}>
            <option value="0">Please select</option>

            {data && data.map((eachOption) => {
                return <option key={eachOption.value} value={eachOption.value}>{eachOption.label}</option>
            })}
        </select>
    )
}

export default Select;