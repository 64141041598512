import { useState } from "react";
import FilterItem from "./FilterItem";

const Filter = ({ columns, onSearch, onAll }) => {

    const [selColumn, setSelColumn] = useState();
    const [inputList, setinputList] = useState([{ column: '', operator: '', value: '' }]);

    const handleFieldChange = (event, index) => {
        const { value } = event.target;

        const list = [...inputList];
        list[index]['column'] = value;
        setinputList(list);

        let tColumn = columns.find(item => item.name === value);
        setSelColumn(tColumn);
    }

    const handleOperatorChange = (event, index) => {
        const { value } = event.target;

        const list = [...inputList];
        list[index]['operator'] = value;
        setinputList(list);
    }

    const handleValueChange = (event, index) => {
        const { name, value } = event.target;

        let tValue;
        if (selColumn.dataType === "options" || selColumn.dataType === "reference") {
            tValue = value;
        }
        else if (selColumn.dataType === "boolean") {
            tValue = value;
        }
        else if (selColumn.dataType === "number") {
            tValue = parseInt(value);
        }
        else {
            tValue = value;
        }

        const list = [...inputList];
        list[index]['value'] = tValue;
        setinputList(list);
    }

    const handleremove = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    }

    const handleaddclick = () => {
        setinputList([...inputList, { column: '', operator: '', value: '' }]);
    }

    const resetFilter = () => {
        const list = [...inputList];
        list.splice(0, 1);
        setinputList(list);
    }

    return (
        <div className="filterToolbar mb-2">
            <div className="mb-2" style={{ marginLeft: "0.4rem", width: "10rem" }}>
                <button className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={() => onSearch(inputList)}>Search</button>
                <button className="btn btn-outline-primary bg-white fw-bold btn-sm" onClick={() => { onAll(); resetFilter() }}>All</button>
            </div>
            <div className="filterContainer">
                {
                    inputList.map((x, i) => {
                        return (
                            <table key={i} className="d-flex mb-2">
                                <tbody>
                                    <FilterItem index={i} columns={columns} handleFieldChange={(e) => handleFieldChange(e, i)} handleOperatorChange={(e) => handleOperatorChange(e, i)} handleValueChange={(e) => handleValueChange(e, i)} handleAddClick={(e) => handleaddclick(e)} handleRemove={(i) => handleremove(i)} />
                                </tbody>
                            </table>
                        );
                    })}
            </div>
        </div>
    )
}

export default Filter;