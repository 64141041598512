import React, { useEffect, useState } from 'react';
import Card from "../card";
import gs from '../../services/gs';
import "./styles.css";

const QuickLinks = () => {

    const [data, setData] = useState();

    useEffect(() => {
        gs.getList("prtl_quick_link", "title,image_url,link_url,description", [{ column: 'type', operator: 'IS', value: "1" }, { column: 'active', operator: 'IS', value: "true" }], "sort_order", "ASC", 1).then((response) => {
            setData(response);
        });
    }, []);

    return (
        <div className="container-fluid quick-links">
            <div className='row g-0'>
                {data && data.map((item, index) => (
                    <Card key={index} data={item} />
                ))}
            </div>
        </div>
    );
};

export default QuickLinks;