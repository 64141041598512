import { useRef } from "react";

const toastRef = useRef;

const addMessage = (mode, text) => {
    return toastRef.current.addMessage({ mode, message: text });
}

const portaltoast = {
    addMessage,
}

export default portaltoast;