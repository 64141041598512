import { useRef, useSyncExternalStore } from 'react';
import { isModalActive, registerContainer } from '../core/store'

export function useModalContainer(props) {
    const { subscribe, getSnapshot, setProps } = useRef(
        registerContainer(props)
    ).current;
    setProps(props);
    const snapshot = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

    function getModalToRender(cb) {
        if (!snapshot) return [];

        const toRender = new Map();

        snapshot.forEach(toast => {
            const { position } = toast.props;
            toRender.has(position) || toRender.set(position, []);
            toRender.get(position).push(toast);
        });

        return Array.from(toRender, p => cb(p[0], p[1]));
    }

    return {
        getModalToRender,
        isModalActive,
        count: snapshot?.length
    };
}