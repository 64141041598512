import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListView from "../components/ListView";
import gs from "../services/gs";

const ListPage = ({ modalTable, isModal = false, onSelect }) => {

  const { paramTable } = useParams();
  const [table, setTable] = useState();

  const [dbObject, setDbObject] = useState();
  const [columns, setColumns] = useState();
  const [data, setData] = useState();
  const [pagination, setPagination] = useState();
  const [UIActions, setUIActions] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterConditions, setFilterConditions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setTable(isModal ? modalTable : paramTable);
  }, [paramTable, modalTable, isModal])

  useEffect(() => {
    if (table !== undefined) {
      gs.getByName("db_object", table).then((res) => {
        setDbObject(res);
      });
      gs.getListColumns("1", table).then((res) => {
        setColumns(res);
        console.log(res);
      });
      gs.getListData(table, [], "id", "ASC", 1, rowsPerPage).then((res) => {
        setData(res.data);
        setPagination(res.pagination);
        console.log(res);
      });
      gs.getUIActions(table, "list").then((res) => {
        setUIActions(res);
        //console.log(res);
      });
    }
  }, [table]);


  const onPagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    gs.getListData(table, filterConditions, "id", "ASC", pageNumber, rowsPerPage).then((res) => {
      setData(res.data);
      setPagination(res.pagination);
      //console.log(res);
    });
  }

  const onRowsPerPageChange = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage)
    gs.getListData(table, filterConditions, "id", "ASC", currentPage, rowsPerPage).then((res) => {
      setData(res.data);
      setPagination(res.pagination);
      console.log(res.data);
    });
    onSelect('sending data from ListPage');
  }

  const onFilter = (filterCondition) => {
    console.log(filterCondition);
    setFilterConditions(filterCondition);
    setCurrentPage(1);
    gs.getListData(table, filterCondition, "id", "ASC", currentPage, rowsPerPage).then((res) => {
      setData(res.data);
      setPagination(res.pagination);
      //console.log(res);
    });
  }

  return (
    <div>
      {(dbObject && columns && data && pagination) && <ListView isModal={isModal} onSelect={onSelect} table={table} title={dbObject.label} columns={columns} data={data} UIActions={UIActions} showFilter="true" onFilter={onFilter} pagination={pagination} onPagination={onPagination} onRowsPerPageChange={onRowsPerPageChange} />}
    </div>
  );
};

export default ListPage;