import MenuItem from "./menu-item";

const MenuList = ({ list = [], onMenuItemClick}) => {
  return (
    <ul className="menu-list-container">
      {list && list.length
        ? list.map((listItem) => (
            <MenuItem
              key={listItem.id}
              item={listItem}
              onMenuItemClick={onMenuItemClick}
            />
          ))
        : null}
    </ul>
  );
};

export default MenuList;
