import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gs from '../../services/gs';
import "./styles.css";

const MegaMenu = () => {
    const [data, setData] = useState();

    useEffect(() => {
        gs.getList("app_menu", "", [{ column: 'type', operator: 'IS', value: "2" }], "sort_order", "ASC", 1).then((response) => {
            setData(response);
        })
    }, []);

    return (

        <ul className="mega-menu">
            {data && data.map((eachRow) => {
                return (
                    <li key={eachRow.id} >
                        {eachRow.url.startsWith('http') ? (<a className='mega-menu-item' href={eachRow.url} target="_blank" rel="noreferrer">{eachRow.label}</a>) : (<Link className='mega-menu-item' to={eachRow.url}>{eachRow.label}</Link>)}
                    </li>
                )
            })}
        </ul>

    )
}

export default MegaMenu;