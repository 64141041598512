import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Filter from "./Filter";
import Pagination from "./Pagination";

const ListView = ({ isModal = false, onSelect, table, data, columns, UIActions, title, showFilter, onFilter, pagination, onPagination, onRowsPerPageChange }) => {

    const navigate = useNavigate(); //used inside UI Action script

    const [showHideFilter, setShowHideFilter] = useState(false);

    const onPaginationClick = (pageNumber) => {
        onPagination(pageNumber)
    }

    const renderCell = (eachRow, column, index) => {

        let tcolumns = column.name.indexOf('.') > -1 && column.name.split('.');
        let tname = '';
        if (tcolumns.length > 1) {
            tname = (typeof (eachRow[tcolumns[0]][tcolumns[1]]) === "object" && eachRow[tcolumns[0]][tcolumns[1]] !== null) ? eachRow[tcolumns[0]][tcolumns[1]].label : eachRow[tcolumns[0]][tcolumns[1]];
        } else {
            tname = eachRow[column.name];
        }

        if (index === 0) {
            return (<div className="table-cell" key={index}>
                {isModal ? (<Link style={{ textDecorationLine: "none" }} onClick={() => { onSelect(eachRow); }}>{eachRow[column.name]}</Link>) : (<Link className="" style={{ textDecorationLine: "none" }} to={`/auxin/form/${column.refTable}/${eachRow[column.name]}`}>{eachRow[column.name]}</Link>)}
            </div>);
        }

        let cellContent;

        switch (column.dataType) {
            case 'reference':
                cellContent = eachRow[`${column.name}_label`];
                break;
            case 'url':
                cellContent = <a href={tname} target="_blank" rel="noreferrer" >{tname}</a>;
                break;
            case 'boolean':
                cellContent = tname === true ? "Yes" : "No";
                break
            default:
                cellContent = tname;
                break
        }
        return (
            <div className="table-cell" key={index}>
                {cellContent}
            </div>
        );
    }

    const onNew = (script) => {
        console.log(script);
        eval(script);
    }

    const onSearch = (inputList) => {
        console.log(inputList);
        onFilter(inputList);
    }

    const onAll = () => {
        onFilter([]);
        setShowHideFilter(!showHideFilter);
    }

    const toggleFilter = () => {
        setShowHideFilter(!showHideFilter);
    }

    return (
        // <div style={{ height: "100vh" }}>
        <div className={`table-root-wrapper table-layout-normal  ${isModal ? "table-model-height" : "table-desktop-height"}`} role="presentation">
            <div className="table-root-wrapper-body table-layout-normal">
                <div className="table-root table-layout-normal">
                    <div className="bg-light">
                        <div className="d-flex justify-content-between align-items-center ms-1 me-1 py-1">
                            <div className="d-flex align-items-center">
                                {showFilter === 'true' && <button className="btn btn-sm" onClick={toggleFilter}><i className="bi bi-funnel"></i></button>}
                                {title && <label className='ps-2 fw-bold text-secondary me-2'>{title}</label>}
                            </div>
                            {UIActions && UIActions.map((action) => {
                                return (
                                    <button key={action.id} className="btn btn-outline-primary bg-white fw-bold btn-sm me-1" onClick={() => onNew(action.script)}>{action.name}</button>
                                )
                            })}
                        </div>
                        {showHideFilter && <Filter columns={columns} onSearch={onSearch} onAll={onAll} />}
                    </div>
                    <div className="table-header">
                        <div className="table-header-viewport" role="presentation">
                            <div className="table-header-container" role="rowgroup">
                                <div className="table-header-row" role="row">
                                    {columns && columns.map((column, index) => {
                                        return (
                                            <div className="table-header-cell" style={{ width: "200px" }} key={index}>{column.label}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-body">
                        <div className="table-body-viewport">
                            <div className="table-cols-viewport">
                                <div className="table-cols-container">
                                    {data && data.map((eachRow, index) => {
                                        return (
                                            <div className="table-row" key={index}>
                                                {columns.map((column, index) => {
                                                    return (
                                                        renderCell(eachRow, column, index)
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination pagination={pagination} onPaginationClick={onPaginationClick} onRowsPerPageChange={onRowsPerPageChange} />
        </div>
        // </div >
    );
}

export default ListView;