import { Type, isFn, isNum, isStr } from '../utils';
import { genModalId } from './genModalId';

import {
    clearWaitingQueue,
    getModal,
    isModalActive,
    onChange,
    pushToast,
    removeToast,
    toggleToast
} from './store';

/**
 * Generate a toastId or use the one provided
 */
function getModalId(options) {
    return options && (isStr(options.toastId) || isNum(options.toastId))
        ? options.toastId
        : genModalId();
}

/**
 * If the container is not mounted, the modal is enqueued
 */
function dispatchModal(content, options) {
    pushToast(content, options);
    return options.toastId;
}

/**
 * Merge provided options with the defaults settings and generate the toastId
 */
function mergeOptions(type, options) {
    return {
        ...options,
        type: (options && options.type) || type,
        toastId: getModalId(options)
    };
}

function createToastByType(type) {
    return (content, options) => dispatchModal(content, mergeOptions(type, options));
}

function modal(content, options) {
    return dispatchModal(content, mergeOptions(Type.DEFAULT, options));
}

modal.loading = (content, options) =>
    dispatchModal(
        content,
        mergeOptions(Type.DEFAULT, {
            isLoading: true,
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false,
            ...options
        })
    );


function handlePromise(promise, { pending, error, success }, options) {
    let id;

    if (pending) {
        id = isStr(pending)
            ? modal.loading(pending, options)
            : modal.loading(pending.render, {
                ...options,
                ...(pending)
            });
    }

    const resetParams = {
        isLoading: null,
        autoClose: null,
        closeOnClick: null,
        closeButton: null,
        draggable: null
    };

    const resolver = (type, input, result) => {
        // Remove the modal if the input has not been provided. This prevents the modal from hanging
        // in the pending state if a success/error modal has not been provided.
        if (input == null) {
            modal.dismiss(id);
            return;
        }

        const baseParams = {
            type,
            ...resetParams,
            ...options,
            data: result
        };
        const params = isStr(input) ? { render: input } : input;

        // if the id is set we know that it's an update
        if (id) {
            modal.update(id, {
                ...baseParams,
                ...params
            });
        } else {
            // using modal.promise without loading
            modal(params.render, {
                ...baseParams,
                ...params
            });
        }

        return result;
    };

    const p = isFn(promise) ? promise() : promise;

    //call the resolvers only when needed
    p.then(result => resolver('success', success, result)).catch(err =>
        resolver('error', error, err)
    );

    return p;
}

modal.promise = handlePromise;
modal.success = createToastByType(Type.SUCCESS);
modal.info = createToastByType(Type.INFO);
modal.error = createToastByType(Type.ERROR);
modal.warning = createToastByType(Type.WARNING);
modal.warn = modal.warning;
modal.dark = (content, options) =>
    dispatchModal(
        content,
        mergeOptions(Type.DEFAULT, {
            theme: 'dark',
            ...options
        })
    );

function dismiss(params) {
    removeToast(params);
}

modal.dismiss = dismiss;

modal.clearWaitingQueue = clearWaitingQueue;

modal.isActive = isModalActive;

modal.update = (toastId, options = {}) => {
    const modal = getModal(toastId, options);

    if (modal) {
        const { props: oldOptions, content: oldContent } = modal;

        const nextOptions = {
            delay: 100,
            ...oldOptions,
            ...options,
            toastId: options.toastId || toastId,
            updateId: genModalId()
        };

        if (nextOptions.toastId !== toastId) nextOptions.staleId = toastId;

        const content = nextOptions.render || oldContent;
        delete nextOptions.render;

        dispatchModal(content, nextOptions);
    }
};
modal.done = (id) => {
    modal.update(id, {
        progress: 1
    });
};
modal.onChange = onChange;
modal.play = (opts) => toggleToast(true, opts);
modal.pause = (opts) => toggleToast(false, opts);

export { modal };